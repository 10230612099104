import { ComponentProps, FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import TeamTableAction from '../../elements/table-actions/team-table-action';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import TableTeamWithActionMenu from '../../elements/table/table-team-with-action-menu';
import HeaderTeamPage from '../../components/headers-page/header-team-page';
import { FetchFilter } from '../../data-structures/interfaces/school.interfaces';
import { fetchTeam } from '../../services/school.service';
import TableProfessorsListLoadingComponent from '../../components/tables/table-professors-list-loading-component';
import FilterTeamPageComponent from '../../components/filters/filter-team-page-component';


let outsideAtBottom = false;

const TeamListPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const { t } = useTranslation();
    const dateFormat = require('dateformat');
    const [actifTab, setActifTab] = useState<string>("all");
    const [listToDisplay, setListToDisplay] = useState<any>([]);
    const connectedUser = useMemo(() => { return props.connectedUser }, [props.connectedUser]);
    const [activateSnakbar, setActivateSnakbar] = useState<boolean>(false);
    const [beforeFormat, setBeforeFormat] = useState<any>()
    const [loading, setLoading] = useState<boolean>(true)
    const [atBottom, setAtBottom] = useState<boolean>(false);
    outsideAtBottom = atBottom;
    const [displayFilter, setDisplayFilter] = useState<FetchFilter>({
        schoolId: connectedUser.idSchool,
        type: "all",
        number: 0,
        status: "all",
        search: ""
    })


    const headersAll = [
        { title: t('team'), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('role'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('lastVisitProfile'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const headersProf = [
        { title: t('filterProfessors'), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('lastVisitProfile'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('courseTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('biography'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const headersAdministrators = [
        { title: t('assistant'), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('lastVisitProfile'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('biography'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const status = [
        { label: t('Enattente'), value: "Enattente", color: "warning" },
        { label: t('Admis'), value: "Admis", color: "success" },
        { label: t('Suspendu'), value: "Suspendu", color: "danger" },
    ]

    const fetchData = async (displayFilter: FetchFilter) => {        
        if (displayFilter.number === 0) {
            setLoading(true)
        }
        let result: any = await fetchTeam(displayFilter)        
        if (displayFilter.number === 0) {
            setLoading(false)
            setBeforeFormat(result)
        } else if (displayFilter.number !== 0 && result.length !== 0) {
            let updated = [...beforeFormat, ...result]            
            setBeforeFormat(updated)
        }

    }

    useEffect(() => {
        displayData()
    }, [beforeFormat])


    useEffect(() => {
        fetchData(displayFilter)
    }, [displayFilter])



    const displayData = async () => {
        let formatted;
        formatted = await formatObject(beforeFormat)
        setListToDisplay(formatted)

    }

    useEffect(() => {
        formatObject(beforeFormat)
    }, [beforeFormat])

    const formatObject = async (toFormat: any) => {
        let result = {
            headers: actifTab === "all" ? headersAll : actifTab === "prof" ? headersProf : headersAdministrators,
            body: toFormat?.map((team: any) => {
                let res = getColumnsContent(actifTab, team);
                return {
                    row: {
                        team: team,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: res
                    }
                }
            })
        }
        return result;
    }

    const getColumnsContent = (tab: string, data: any) => {
        const actualStatus: any = status?.find(obj => obj?.value?.toLowerCase() === data?.status?.toLowerCase());
        if (tab === "all") {
            return [
                { content: data?.fullName, classNames: "", actionName: '' },
                { content: data?.role === "PROF" ? t('Professeur_1') : t('schoolAssistant'), classNames: "text-center", actionName: '' },
                { content: data?.inscriptionDate === null ? "-" : data?.inscriptionDate, classNames: "text-center", actionName: '' },
                { content: data?.lastVisitDate === "-" ? "-" : data?.lastVisitDate + " " + t('joursVisit'), classNames: "text-center", actionName: '' },
                { content: < TeamTableAction status={actualStatus?.label} color={actualStatus?.color} id={data?.id} role={data?.role} activateSnakBar={() => { setActivateSnakbar(true) }} />, classNames: `text-end with-action-edit`, actionName: '' },
            ]
        } else if (tab === "prof") {
            return [
                { content: data?.fullName, classNames: "", actionName: '' },
                { content: data?.inscription === null ? "-" : dateFormat(data?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                { content: data?.inscriptionDate === null ? "-" : data?.inscriptionDate, classNames: "text-center", actionName: '' },
                { content: data?.numberOfTrainings, classNames: "text-center", actionName: '' },
                {
                    content: (data?.bio !== null && data?.pp !== '') ?
                        <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">check</span> :
                        <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">highlight_off</span>,
                    classNames: (data?.pp !== null && data?.pp !== '') ?
                        "text-center with-icon badge-text success p-0 m-0 relative-position" :
                        "text-center with-icon badge-text secondary p-0 m-0 relative-position",
                    actionName: ''
                },
                { content: data?.status === "Enattente" ? < TeamTableAction status={actualStatus?.label} color={actualStatus?.color} id={data?.id} role={data?.role} activateSnakBar={activateSnakbar} /> : <span className={`text-end badge-text ${actualStatus?.color}`}>{data?.status}</span>, classNames: `text-end with-action-edit`, actionName: '' },
            ]
        } else if (tab === "ADMINSTRATOR") {
            return [
                { content: data?.fullName, classNames: "", actionName: '' },
                { content: data?.inscriptionDate === null ? "-" : dateFormat(data?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                { content: data?.inscriptionDate === null ? "-" : data?.inscriptionDate, classNames: "text-center", actionName: '' },

                {
                    content: (data?.pp !== null && data?.pp !== '') ?
                        <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">check</span> :
                        <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">highlight_off</span>,
                    classNames: (data?.pp !== null && data?.pp !== '') ?
                        "text-center with-icon badge-text success p-0 m-0 relative-position" :
                        "text-center with-icon badge-text secondary p-0 m-0 relative-position",
                    actionName: ''
                },
                { content: data?.status === "Enattente" ? < TeamTableAction status={actualStatus?.label} color={actualStatus?.color} id={data?.id} role={data?.role} activateSnakBar={activateSnakbar} /> : <span className={`text-end badge-text ${actualStatus?.color}`}>{data?.status}</span>, classNames: `text-end with-action-edit`, actionName: '' },
            ]
        }

    }
    const isBottom = () => {
        return ((window.innerHeight + window.scrollY) >= document.body.offsetHeight);
    }

    const handleScroll = (event: any) => {
        if (!(atBottom) && isBottom()) {
            setAtBottom(true);
        }
        if (!isBottom()) {
            setAtBottom(false);
        }
    }

    useEffect(() => {
        fetchNextPage()
    }, [atBottom])

    const fetchNextPage=()=>{
        console.log(actifTab);
        
        if((actifTab==="all" && beforeFormat?.length < props?.teamData?.teamSize)
            || (actifTab==="prof" && beforeFormat?.length < props.teamData.sizeProfs)
            || (actifTab==="ADMINSTRATOR" && beforeFormat?.length < props.teamData.sizeAssistants)
        ){
            setDisplayFilter({
                schoolId: connectedUser.idSchool,
                type: displayFilter.type,
                number: displayFilter.number+1,
                status: displayFilter.status,
                search: displayFilter.search
            })
        }
    }
    

    useEffect(() => {
        //loading
        fetchData(displayFilter)
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            setDisplayFilter({
                schoolId: connectedUser.idSchool,
                type: "all",
                number: 0,
                status: "all",
                search: ""
            })
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])


    const updateActifTab = (value: string) => {
        setActifTab(value);
        setDisplayFilter({
            schoolId: connectedUser.idSchool,
            type: value,
            number: 0,
            status: displayFilter.status,
            search: displayFilter.search
        })
    }

    const updateFilterData = (key: string, value: string) => {
        
        let updatedFilter: FetchFilter = displayFilter;
        if (key === "status") {
            updatedFilter.status = value;
        } else if (key === "search") {
            updatedFilter.search = value;
        }        
        setDisplayFilter(updatedFilter);
        fetchData(updatedFilter)
    }



    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <HeaderTeamPage updateActifTab={(e: string) => updateActifTab(e)} actifTab={actifTab} />
                <div className="container-fluid m-0 p-0 mx-auto" style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-center">
                        {(loading === false) ? <div className="col-md-12 p-0 m-0 mt-5">
                            <FilterTeamPageComponent
                                    displayFilter={displayFilter}
                                    updateFiltre={(key: string, value: string) => updateFilterData(key, value)}
                                    actifTab={actifTab}
                                />
                            {listToDisplay?.body?.length > 0 && listToDisplay?.body?.length !== undefined ? <>
                                
                                <TableTeamWithActionMenu
                                    height={10}
                                    data={listToDisplay}
                                    doAction={(value: any) => {console.log("value ", value) }}
                                    actifTab={actifTab}
                                />
                            </> : <>
                                <div className="w-100">
                                    <div className='wrapper-empty-list mt-5 pt-5'>
                                        <EmptyListElement lines={[t('noTeamDefault_1'), t('noTeamDefault_2')]} />
                                    </div>
                                </div>
                            </>}
                        </div> :
                            <>
                                <TableProfessorsListLoadingComponent />
                            </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    teamData: state.profReducer
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamListPage);