import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useHistory } from 'react-router-dom';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from 'react-i18next';
import { getProductList } from '../../global-state/actions/finance-actions';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { facebookSendEvent } from '../../helpers/export-functions';
import { saveActionHistory } from '../../services/history.service';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getContract } from '../../global-state/actions/app-container-actions';
import { getLimitationsApi, getOffersApi } from '../../services/finance.service';


const OfferListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();

    const offerFreeId = process.env.REACT_APP_OFFER_ID_FREE
    const offerFreeLabel = process.env.REACT_APP_OFFER_LABEL_FREE
    const offerProId = process.env.REACT_APP_OFFER_ID_PRO
    const offerProLabel = process.env.REACT_APP_OFFER_LABEL_PRO
    const offerBusinessId = process.env.REACT_APP_OFFER_ID_BUSINESS
    const offerBusinessLabel = process.env.REACT_APP_OFFER_LABEL_BUSINESS

    const history = useHistory();
    const [checked, setChecked] = useState(false)
    const handleChange = (e: any) => {
        setChecked(e.target.checked);
    };
    const [offers, setOffers] = useState<any>();
    const [downGrade, setDownGrade] = useState<any>();

    const getOffers = async () => {
        const result = await getOffersApi()
        setOffers(result?.filter((test: any) => test?.pricings[0]?.price !== 0))
    }

    useEffect(() => {
        getOffers()
        props?.getContract(props?.schoolInfo?.id, "SCHOOL");

        (props?.subscription?.idOffer != offerProId) ? setDownGrade(true) : setDownGrade(false)

    }, [])




    const goAction = async (id: any, label: any) => {
        await saveActionHistory("koors_initiate_checkout", props?.connectedUser?.idPerson, props?.connectedUser?.idSchool)

        const { userProfileDetails, allOffers } = props
        let uri: string;
        /* START API FACEBOOK INITIATE CHECKOUT */
        if (label === offerProLabel || label === offerBusinessLabel) {
            let price;
            if (label === offerBusinessLabel) {
                uri = "?period=monthly"
            } else {
                const offerPrices = allOffers?.find((el: any) => el.label === offerProLabel).pricings

                uri = checked ? "?period=monthly" : "?period=yearly"
                price = offerPrices.find((el: any) => el.period === (checked ? 'MONTH' : 'YEAR'))?.price * (checked ? 12 : 2)


            }
            const predicted_ltv = price;
            if (process.env.REACT_APP_RUN_DATAGOG_RUM === 'true') {
                facebookSendEvent(
                    undefined,
                    undefined,
                    userProfileDetails.firstName,
                    userProfileDetails.lastName,
                    userProfileDetails.email,
                    "InitiateCheckout",
                    label,
                    predicted_ltv,
                )
            }
            /* END API FACEBOOK INITIATE CHECKOUT */
        }

        await getLimitationsApi(props?.schoolInfo?.id, id).then(async (resp: any) => {
            const statusList: any = [];
            resp?.data?.map((limit: any) => {
                statusList.push(limit.status)

            })

            await props?.clearHistoryArray();
            await props?.redirect(label, "");
            history.push(`${returnStartPathUrl()}/offer/payment/${id}${uri}`)
        })
    }

    function capitalizeFirstLetter(word: string) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    return (<>
        <div style={{ marginTop: 64 }} className="final-exam-passage" >
            <div className="d-flex flex-column justify-content-center m-0">
                <div style={{ backgroundColor: "#FFFFFF" }} >
                    <div style={{ maxWidth: '1200px', margin: 'auto' }} className="d-flex flex-row justify-content-between">
                        <div className="col-md-12 mt-4 pt-2 px-0 ">
                            <span className="H2-Headline " > {t('offerList')} <span className="H2-Headline " style={{ marginLeft: '10px' }}></span></span>
                        </div>

                    </div>
                </div>

                <div style={{ maxWidth: '900px', margin: 'auto', marginTop: 64 }}>
                    <div className='d-flex flex-row align-items-center justify-content-center mt-4'>
                        <input className="input-style-button" type="checkbox" id="toggleBtn" onChange={(e) => handleChange(e)} />
                        <div className="toggle-container-button" >
                            <label className="label-style-button" htmlFor="toggleBtn">
                                <span className="on2 ">{t('monthly')}</span>
                                <span className="off2 " style={{ paddingLeft: checked === true ? "70px" : "20px" }}>{t('yearly_2')}</span>
                                <input type="checkbox" style={{ display: 'none' }} />
                            </label>
                        </div>
                    </div>




                    <div className=" d-flex justify-content-center mt-4 align-items-center detail-liste-responsive-session" style={{ marginLeft: "20px" }}>

                        {downGrade ?
                            <div className="bg-with-border offer-card mb-3 mt-1 me-4" style={{ backgroundColor: "#FFFFFF", height: "774px" }}>
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-column align-items-start mb-2">
                                        <div className="d-flex flex-column align-items-start ">
                                            <span className="H3-Headline black-800">Gratuite</span>

                                            <span className="mt-2 body-xl neutral-2">{t('essentialTools')}</span>
                                        </div>

                                        <div className="my-3 d-flex flex-column" style={{ height: "135px" }}>
                                            {checked &&
                                                <span className="black-800 offer-price2">0€</span>
                                            }
                                            {!checked &&
                                                <span className="black-800 offer-price2">0€</span>}
                                            <span className="mt-2 body-xl neutral-2">{t('forever')} </span>
                                        </div>
                                        {(props?.subscription?.idOffer == offerFreeId && !!props?.contract && props?.contract.length > 0) ?
                                            <button disabled type="button" onClick={() => { goAction(offerFreeId, offerFreeLabel) }} className="btn-Primary large" style={{ width: 304 }}>
                                                {t('actuelOffer')}
                                            </button> :
                                            <button type="button" onClick={() => { goAction(offerFreeId, offerFreeLabel) }} className="btn-Primary large" style={{ width: 304 }}>
                                                {t('selectFreeOffer')}
                                            </button>
                                        }



                                    </div>

                                    <div className="w-100 d-flex my-4">
                                        <hr className="hr-payment" style={{ width: '100%', margin: 0 }} />
                                    </div>
                                    <div className="mt-2 d-flex flex-column">

                                        <div className="d-flex flex-row mb-3">
                                            <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                                check_circle_outline
                                            </span>
                                            <span className="ms-3 body-md black-800">20 {t('students')}</span>
                                        </div>
                                        <div className="d-flex flex-row mb-3">
                                            <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                                check_circle_outline
                                            </span>
                                            <span className="ms-3 body-md black-800">3 {t('courses')}</span>
                                        </div>
                                        <div className="d-flex flex-row mb-3">
                                            <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                                check_circle_outline
                                            </span>
                                            <span className="ms-3 body-md black-800">{t('pathAndSessions')}</span>
                                        </div>
                                        <div className="d-flex flex-row mb-3">
                                            <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                                check_circle_outline
                                            </span>
                                            <span className="ms-3 body-md black-800">1 {t('Professeurs')}</span>
                                        </div>
                                        <div className="d-flex flex-row mb-3">
                                            <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                                check_circle_outline
                                            </span>
                                            <span className="ms-3 body-md black-800">1 {t('schoolAssistant')}</span>
                                        </div>
                                        <div className="d-flex flex-row mb-3">
                                            <span className="material-icons-outlined green-800" style={{ marginTop: '2px', visibility: "hidden" }}>
                                                check_circle_outline
                                            </span>
                                            <span className="ms-3 body-md black-800"></span>
                                        </div>








                                    </div>
                                </div>
                            </div> : <span></span>}

                        <div className="bg-with-border offer-card mb-3 mt-1 me-4" style={{ backgroundColor: "#FFFFFF" }}>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-column align-items-start mb-2">
                                    <div className="d-flex flex-column align-items-start ">
                                        <span className="H3-Headline black-800">Professionnelle</span>

                                        <span className="mt-2 body-xl neutral-2">{t('forProfessionalsAndNonProfessionals')}</span>
                                    </div>

                                    <div className="my-3 d-flex flex-column " style={{ height: "135px" }}>
                                        {checked &&
                                            <span className="offer-price2">99€</span>}
                                        {!checked &&
                                            <span className="black-800 offer-price2">1089€</span>}
                                        {!checked &&
                                            <span className="mt-2 body-xl neutral-2">HT / {t('perYear')} </span>
                                        }
                                        {checked &&
                                            <span className="mt-2 body-xl neutral-2">HT / {t('perMonth')} </span>
                                        }

                                        {!checked &&
                                            <span className="mt-1 body-xl neutral-2">({t('yearlyBill')}) </span>}
                                    </div>


                                    {(props?.subscription?.idOffer == offerProId && !!props?.contract && props.contract.length > 0) ?
                                        <button disabled type="button" onClick={() => { goAction(offerProId, offerProLabel) }} className="btn-Primary large" style={{ width: 304 }}>
                                            {t('actuelOffer')}
                                        </button> :
                                        <button type="button" onClick={() => { goAction(offerProId, offerProLabel) }} className="btn-Primary large" style={{ width: 304 }}>
                                            {t('selectProOffer')}
                                        </button>
                                    }


                                </div>

                                <div className="w-100 d-flex my-4">
                                    <hr className="hr-payment" style={{ width: '100%', margin: 0 }} />
                                </div>
                                <div className="mt-2 d-flex flex-column">

                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">200 {t('students')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">20 {t('courses')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('pathAndSessions')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">2 {t('Professeurs')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">2 {capitalizeFirstLetter(t('assistants'))}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('Webinar')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">Coupons de réduction</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="bg-with-border offer-card mb-3 mt-1 me-4" style={{ backgroundColor: "#FFFFFF" }}>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-column align-items-start mb-2">
                                    <div className="d-flex flex-column align-items-start ">
                                        <span className="H3-Headline black-800">Business</span>
                                        <span className="mt-2 body-xl neutral-2">{t('illimitedAccess')}

                                        </span>
                                    </div>

                                    <div className="mt-5 mb-3 d-flex flex-column " style={{ height: "135px" }}>
                                        {checked &&
                                            <span className="black-800 offer-price2">8€</span>}
                                        {!checked &&
                                            <span className="black-800 offer-price2">8€</span>}

                                        <span className="mt-2 body-xl neutral-2">  {t('perStudent')} ( {t('minimum')} 20 ) <br /> HT / {t('perMonth')} </span>

                                    </div>



                                    {(props?.subscription?.idOffer == offerBusinessId && !!props?.contract && props.contract.length > 0) ?
                                        <button disabled type="button" onClick={() => { goAction(offerBusinessId, offerBusinessLabel) }} className="btn-Primary large" style={{ width: 304 }}>
                                            {t('actuelOffer')}
                                        </button> :
                                        <button type="button" onClick={() => { goAction(offerBusinessId, offerBusinessLabel) }} className="btn-Primary large" style={{ width: 304 }}>
                                            {t('selectBussinessOffer')}
                                        </button>
                                    }
                                </div>

                                <div className="w-100 d-flex my-4">
                                    <hr className="hr-payment" style={{ width: '100%', margin: 0 }} />
                                </div>
                                <div className="mt-2 d-flex flex-column">

                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('illimitedNumberOfStudents')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('illimitedNumberOfCours')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('pathAndSessions')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('illimitedNumberOfProfessors')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('illimitedNumberOfAssistants')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">{t('Webinar')}</span>
                                    </div>
                                    <div className="d-flex flex-row mb-3">
                                        <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                            check_circle_outline
                                        </span>
                                        <span className="ms-3 body-md black-800">Coupons de réduction</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo,
    subscription: state.schoolInfoReducer.subscription,
    contract: state.schoolInfoReducer.contract,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getContract,
    openModal, getProductList,
    redirect, clearHistoryArray
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfferListComponent);