import { fetchData } from "../base/functions/Functions";
import { FetchFilter } from "../data-structures/interfaces/school.interfaces";
import axiosInstance from "../helpers/interceptors";


let personUrl=process.env.REACT_APP_BASE_URL2;

const createPayment = async (data: any) => {
    const dataResponse = await axiosInstance.post(
     process.env.REACT_APP_BASE_URL14 + "person/createPayment", data
   ).then((res) => {    
     return res.data;
   });
   return dataResponse;
  }

  const upgradeSubscription = async (data: any) => {
    const dataResponse = await axiosInstance.post(
     process.env.REACT_APP_BASE_URL14 + "person/upgradeSubscription", data
   ).then((res) => {    
     return res.data;
   });
   return dataResponse;
  }

  const upgradeContract = async (data: any) => {
    const dataResponse = await axiosInstance.post(
     process.env.REACT_APP_BASE_URL14 + "person/upgradeContract", data
   ).then((res) => {    
     return res.data;
   });
   return dataResponse;
  }

  const  fetchTeam = async(filter:FetchFilter)=> {
    try {
      const response = await fetchData(
        "POST",
        `${personUrl}person/fetchSchoolTeam`,
        filter
      );
      return response;
    } catch (error) {
      console.error("Error fetching list team:", error);
    }
  }

  export { createPayment , upgradeSubscription , upgradeContract , fetchTeam};