import { FC } from "react";
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import FooterComponent from "../../components/footer-component";
import ProfessorsListPage from "./professors-list-page";
import ProfessorSheetPage from "../professors/professor-sheet-page";
import RouteWithBreadCrumb from "../../navigations/RouteWithBreadCrumb";
import CustomSwitch from "../../helpers/custom-switch";
import AssistantSheetPage from "./assistant-sheet-page";
import teamListPage from "./team-list-page";


const ProfessorsContainer: FC<any> = (props: any) => {
  return (<>
    <div className="page-wrapper-height">
      <CustomSwitch>
        <RouteWithNavbar exact={true} history={props.history} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/all`} component={teamListPage} />
        <RouteWithBreadCrumb exact={true} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/info/:id`} component={ProfessorSheetPage} />
        <RouteWithBreadCrumb exact={true} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/infoteam/:id`} component={AssistantSheetPage} />
      </CustomSwitch>
    </div>
    <FooterComponent />
  </>
  )
}
export default ProfessorsContainer;