import {  ComponentProps, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import EmptyListElement from "../empty-list/normal-empty-list-element";
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { useHistory } from 'react-router';

interface Props {
    data: any,
    actifTab: string,
    doAction?: any,
    height?: number | string
}

const TableTeamWithActionMenu: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const history = useHistory();
    const { t } = useTranslation();
    let data = useMemo(() => {
        if (props.data.length !== 0) {
            return props.data
        } else {
            return [props.data]
        }
    }, [props.data])

    const displayNoDataMsg = (actifTab: string) => {
        if (actifTab === "all") {
            return <div className="mt-7"><EmptyListElement lines={[t('noAssistant_1'), t('noAssistant_2')]} /></div>
        } else if (actifTab === "prof") {
            return <div className="mt-7"><EmptyListElement lines={[t('noProfessorsList_1'), t('noProfessorsList_2')]} /></div>
        } else if (actifTab === "ADMINSTRATOR") {
            return <div className="mt-7"><EmptyListElement lines={[t('noAssistant_1'), t('noAssistant_2')]} /></div>
        }
    }

    const clickAction = ( row: any) => {
            props?.clearHistoryArray();
            props?.redirect(t("schoolAssistant"), `${returnStartPathUrl()}/professors/all`);
            props?.redirect( row?.team?.fullName, `${returnStartPathUrl()}/professors/infoteam/${ row?.team?.id}`);
             history.push(`${returnStartPathUrl()}/professors/infoteam/${row?.team?.id}`);
            
    }


    return (
        <>
            <div className="mt-3" style={{ minHeight: 'calc(100% - 134px)' }}>
                <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200, overflow: 'initial' }}>
                    {data?.body?.length > 0 ? <table className="w-100 custom-table">
                        <thead className="mb-4">
                            <tr>
                                {data?.headers?.map((header: any) =>
                                (<th key={header.title} scope={header.scope} className={header.classNames}>
                                    <button className={header.buttonClasses}>
                                        {t(header.title)}
                                    </button>
                                </th>)
                                )}
                            </tr>
                        </thead>
                        <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
                            {data?.body?.map((data: any, index: number) =>
                            (
                                <tr key={index + 'row'} style={{ cursor: (data.row.actionName === "") ? 'default' : 'pointer', position: "relative" }} onClick={(e) => clickAction(data.row)} className={data.row.classNames}>
                                    {data.row.columns.map((column: any, index: number) =>
                                    (
                                        <td key={index + 'column'} onClick={(e) => clickAction(data.row)} className={column.classNames} colSpan={column.colspan}>{column.content}</td>
                                    )
                                    )}
                                </tr>
                            )
                            )}
                        </tbody>
                    </table> :
                        displayNoDataMsg(props.actifTab)
                    }
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    teamData: state.profReducer
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect, clearHistoryArray 
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableTeamWithActionMenu);