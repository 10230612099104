import { ComponentProps, FC, useEffect, useMemo, useState,useCallback} from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import SelectElement from '../../elements/select/select-element';
import { FetchFilter } from '../../data-structures/interfaces/school.interfaces';
import axiosInstance from '../../helpers/interceptors';
import { debounce } from "lodash";


const FilterTeamPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const listStatus = [
        { label: t('Tous'), value:"all", id: 1 },
        { label: t('Enattente'), value: 'Enattente', id: 2 },
        { label: t('Admis'), value: 'Admis', id: 3 },
        { label: t('Suspendu'), value: 'Suspendu', id: 4 }
    ]
    const connectedUser = useMemo(() => { return props.connectedUser }, [props.connectedUser]);
    const [filter, setFilter] = useState<FetchFilter>({
        schoolId: connectedUser.idSchool,
        type: "all",
        number: 0,
        status: "all",
        search: ""
    })

    const generateExcelFile = () => {
        const name = t('listeTeam') + " " + props?.schoolInfo?.code
        const lang = localStorage.getItem("language")
        axiosInstance({
            url: process.env.REACT_APP_BASE_URL2 + "person/export/prof/" + connectedUser.idSchool + "/" + name + "/" + lang + "/" + props?.actifTab,
            method: 'POST',
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name + '.csv');
                document.body.appendChild(link);
                link.click();
            })
    }

    const updateFilter = async(key: string, value: string) => {
        console.log("value", value);
        
        await props.updateFiltre(key, value);
    }
    const debounceUpdateMail = useCallback(debounce(updateFilter, 1000), []);

    useEffect(() => {
        if (filter && filter !== props.displayFilter) {
            setFilter(props.displayFilter)
        }
    }, [props.displayFilter])

    //updateFilter("search", e)
    return (
        <div className="d-flex align-items-center justify-content-between flex-wrap mt-5 mb-5">
            <button onClick={() => generateExcelFile()} style={{ minHeight: '45px', marginRight: '10px' }} className="btn-Secondary large icon-left"  >
                <span className="material-icons-outlined" style={{ marginRight: '8px' }} >download</span>
                <div className="m-auto ps-2">{t('exportListe')}</div>
            </button>
            <div className="d-flex align-items-center">
                <div style={{ width: '220px', marginRight: '10px' }}>
                    <SelectElement disabled={false} taille={'lg'} defaultValue={"none"} options={listStatus} onChange={(e: any) => { updateFilter("status", e) }} placeholder={t('statusFilter')} />
                </div>
                <div className="input-form-control" style={{ maxWidth: '220px' }}>
                    <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={filter?.search} onChange={(e: any) => debounceUpdateMail("search", e.target.value)} />
                    <span className="material-icons-outlined"> search </span>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({

}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterTeamPageComponent);