import { ComponentProps, FC, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCountsApi, getLimitationsApi } from '../../services/finance.service';
import { getTeamNumber } from '../../global-state/actions/professor-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';

const HeaderTeamPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const connectedUser = useMemo(() => { return props.connectedUser }, [props.connectedUser]);
    const teamCount = useMemo<number>(() => { return props.teamData.teamSize }, [props.teamData.teamSize]);
    const profCount = useMemo<number>(() => { return props.teamData.sizeProfs }, [props.teamData.sizeProfs]);
    const admistratorsCount = useMemo<number>(() => { return props.teamData.sizeAssistants }, [props.teamData.sizeAssistants]);
    const [schoolOfferDetails, setSchoolOfferDetails] = useState<any>();
    const offer = useMemo(() => { return props.offer }, [props.offer])

    const fetchSchoolOfferDetails = async (schoolId: number) => {
        const result = await getCountsApi(schoolId);
        setSchoolOfferDetails(result);
    }

    const inviteTeamMember = async () => {
        if (!!connectedUser.idSchool) {
            await getLimitationsApi(connectedUser.idSchool, offer?.id).then(async (resp: any) => {
                await props?.openModal({ idModal: ModalIDS.INVITE_TEAM_MEMBER, profiles: resp.data, size: "md" });
            })
        }
    }

    useEffect(() => {
        fetchSchoolOfferDetails(connectedUser.idSchool)
        props.getTeamNumber(connectedUser.idSchool)
    }, [])

    return (
        <div className="col-md-12">
            <div style={{ backgroundColor: '#FFFFFF' }}>
                <div className="d-flex align-items-center justify-content-between flex-wrap pt-5 mx-auto" style={{ maxWidth: '1200px' }}>
                    {<>
                        <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                            {t('team')}
                            <span className="ms-3" style={{ color: "#272727" }}>
                                {teamCount}
                            </span>
                        </h1>
                        {teamCount > 0 &&
                            <div className='d-flex  flex-wrap '>
                                {connectedUser?.role === "role_director" && <div style={{ marginLeft: "5px" }}>
                                    <button type="button" className="btn-Primary large icon-left `" onClick={() => inviteTeamMember()}  >
                                        <div className='d-flex align-items-center'>
                                            <span className="material-icons" style={{ marginRight: '8px' }}>add</span>{t('membreInvitation')}
                                            <div className="m-auto ps-2 ">{t('question').toUpperCase}</div>
                                        </div>
                                    </button>
                                </div>}
                            </div>}
                    </>}
                </div>
                <div>
                    {<div className="d-flex  px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }}>
                        <button className={`btn-Tabs ${(props.actifTab === 'all') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => props.updateActifTab("all")} >
                            {t('allTeam')} ({(teamCount > 0 && teamCount < 10) ? "0" + teamCount : teamCount})
                        </button>
                        <button className={`btn-Tabs ${(props.actifTab === 'prof') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => props.updateActifTab("prof")} >
                            {t('Professeurs')} ({(profCount > 0 && profCount < 10) ? "0" + profCount : profCount})
                            {(schoolOfferDetails?.filter((profile: any) => profile.key = "nb_prof")[0]?.valueContract > 0 &&
                                schoolOfferDetails?.filter((profile: any) => profile.key = "nb_prof")[0]?.valueSchool >= schoolOfferDetails?.filter((profile: any) => profile.key = "nb_prof")[0]?.valueContract) &&
                                <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                        </button>
                        <button className={`btn-Tabs ${(props.actifTab === 'ADMINSTRATOR') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => props.updateActifTab("ADMINSTRATOR")}  >
                            {t('schoolAssistant1')} ({(admistratorsCount > 0 && admistratorsCount < 10) ? "0" + admistratorsCount : admistratorsCount})
                            {(schoolOfferDetails?.filter((profile: any) => profile.key = "nb_administrateur")[0]?.valueContract > 0 && schoolOfferDetails?.filter((profile: any) => profile.key = "nb_administrateur")[0]?.valueSchool >= schoolOfferDetails?.filter((profile: any) => profile.key = "nb_cours")[0]?.valueContract) &&
                                <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                        </button>
                    </ div>}
                </div>
            </div>
        </div>

    )
}


const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    teamData: state.profReducer,
    offer: state.schoolInfoReducer.offer,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getTeamNumber,
            openModal
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderTeamPage);